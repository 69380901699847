import React, { useEffect, useState } from 'react'
import { IAthleteResponse, fetchUserData } from '../../util/fetch'
import * as S from '../../styles/video.styles'
import { ILiveWorkout } from '../../types'
import { Provider } from 'react-redux'
import { createStore } from 'redux'
import rootReducer from '../../services/chat/Redux-store/Redux-reducers'
import * as session from '../../util/session'
import { LOGIN_ROUTE } from '../../constants/routes'
import loadable from '@loadable/component'
import CircularProgress from '@material-ui/core/CircularProgress'
import { theme } from '../../constants/colors'
import { ThemeProvider } from '@material-ui/core/styles'
import { fetchLiveWorkoutDetail } from '../../util/fetch/workouts'
import { withErrorHandler } from '../../components/errorHandler'
import { fetchLiveConfig } from '../../util/fetch/workouts'
import { LiveConfig } from '../../types'

const AgoraLive = loadable(() => import('../../components/live/agoraLive'))

const store = createStore(rootReducer)

interface IProps {
  location: {
    search: string
  }
}

const GATSBY_API_BASE_CLIENT =
  process.env.GATSBY_API_BASE_CLIENT === 'true' ? true : false

export type TUserData = IAthleteResponse | undefined

const Index = ({ location }: IProps) => {
  const [pending, setPending] = useState<boolean>(true)
  const [error, setError] = useState<string | undefined>(undefined)
  const [userData, setUserData] = useState<TUserData>(undefined)
  const [workoutData, setWorkoutData] = useState<ILiveWorkout>(null)
  const [loadingWorkout, setLoadingWorkout] = useState(false)
  const [liveConfig, setLiveConfig] = useState<LiveConfig | null>(null)
  const [loadingConfig, setLoadingConfig] = useState(true)

  const { search } = location
  const id = search.split('?id=').pop()

  const getWorkoutDetail = async () => {
    const response = await fetchLiveWorkoutDetail(Number(id))
    setWorkoutData(response)
    setLoadingWorkout(false)
  }

  const getLiveConfig = async () => {
    const response = await fetchLiveConfig()
    setLiveConfig(response)
    setLoadingConfig(false)
  }

  useEffect(() => {
    if (GATSBY_API_BASE_CLIENT) {
      getLiveConfig()
    } else {
      setLoadingConfig(false)
    }
    if (search !== '') {
      getWorkoutDetail()
    }
  }, [])

  const userId = session.getId()

  const isTrainer = session.isTrainer()

  session.redirectIfNotLoggedIn(LOGIN_ROUTE)

  useEffect((): void => {
    fetchUserData(userId, setUserData, setPending, setError)
  }, [userId])

  if (!userData || loadingWorkout || loadingConfig) {
    return (
      <ThemeProvider theme={theme}>
        <S.LoaderWrap>
          <CircularProgress />
        </S.LoaderWrap>
      </ThemeProvider>
    )
  }

  const workoutDataEnhanced = workoutData && {
    hostId: workoutData.trainer.id,
    ...workoutData,
  }

  const meetingId = workoutData && `channel${workoutData.id}`

  const isOneToMany = workoutData && workoutData.workoutVideoFormat === 'stream'

  const role = isTrainer ? 'host' : isOneToMany ? 'audience' : 'host'

  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        {error && <span>{error}</span>}
        {pending && <span>Loading user data...</span>}

        {workoutData === null && !loadingWorkout ? (
          <S.EmptyState>Workout with this does not exist</S.EmptyState>
        ) : (
          <>
            <S.LoaderWrapLive>
              <CircularProgress />
            </S.LoaderWrapLive>
            <AgoraLive
              meetingId={meetingId}
              workoutData={workoutDataEnhanced}
              userData={userData}
              role={role}
              liveConfig={liveConfig}
            />
          </>
        )}
      </Provider>
    </ThemeProvider>
  )
}

export default withErrorHandler(Index)
