import styled from 'styled-components'
import * as Colors from '../constants/colors'
import { placeholderLive } from '../images/images'
import { PHONE } from '../constants/measurements'
import { isTablet } from 'react-device-detect'

export const Container = styled.div`
  width: 100vw;
  height: 100vh;
  background: url(${placeholderLive});
  background-size: contain;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  img {
    margin-bottom: 0;
  }
`

export const LoaderWrap = styled.div`
  display: flex;
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  background: ${Colors.themeColors.pageBg};
`

export const LoaderWrapLive = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  top: 0;
  right: 0;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 0;
  background: ${Colors.themeColors.pageBg};
`

export const EmptyState = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const BottomWrap = styled.div`
  width: 100%;
  background: black;
  position: absolute;
  bottom: 0;
  height: 170px;
  display: flex;
  flex-wrap: nowrap;
  overflow: scroll;
`

export const ChatHeader = styled.div<{ expanded: boolean }>`
  width: 25%;
  min-width: 280px;
  max-width: 380px;
  height: 40px;
  position: absolute;
  bottom: ${({ expanded }) => (expanded ? undefined : '170px')};
  top: ${({ expanded }) => (expanded ? '0px' : undefined)};
  right: 0;
  background: ${Colors.themeColors.liveBottomBanner};
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
`

export const ChatExpanded = styled.div`
  width: 100%;
  background: white;
  position: relative;
  flex: 1;
  height: 100vh;
  ${() => {
    if (isTablet) {
      return `
      height: 80%;
      `
    }
  }}
  @media (max-width: ${PHONE}) {
    height: 80%;
  }
`

export const ChevronIcon = styled.img<{ expanded: boolean }>`
  width: 25px;
  margin-left: 15px;
  transform: ${({ expanded }) => (expanded ? undefined : 'rotate(180deg)')};
  margin-bottom: ${({ expanded }) => (expanded ? 0 : '3px')} !important;
`

export const PersonImage = styled.img`
  width: 300px;
  height: 170px;
`

export const PersonWrap = styled.div`
  min-width: 280px;
  height: 170px;
  position: relative;
`

export const PersonName = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  color: white;
`

export const NextPersonIcon = styled.img`
  width: 20px;
`

export const Mobile = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  text-align: center;
`
