// Here we Export Type of Actions

export const SET_USER = 'SET_USER'
export const CLEAR_USER = 'CLEAR_USER'

// Channel Types
export const SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL'
export const SET_PRIVATE_CHANNEL = 'SET_PRIVATE_CHANNEL'
export const SET_USER_POSTS = 'SET_USER_POSTS'

// Color of Application
export const SET_COLOR = 'SET_COLOR'
